<template>
    <div class="send-notification-container mt-3">
        <h3 class="mb-2">
            {{ $t('adDeployment.review.sendNotifications.title') }}
        </h3>
        <p>{{ $t('adDeployment.review.sendNotifications.description') }}</p>
        <v-checkbox
            v-model="sendNotifications"
            class="styled-checkbox pl-4"
            color="primary">
            <template #label>
                <span class="mr-1">{{ $t('adDeployment.review.sendNotifications.checkboxLabel') }}</span>
                <notification-emails v-model="emails" />
            </template>
        </v-checkbox>

        <div class="notice-title">
            Want offline attribution for Meta? Make sure to follow these steps once your ads are deployed!
        </div>
        <div class="notice">
            <div class="notice-icon">
                <icon
                    name="indicator-warning"
                    size="20"
                    color="#EE5643" />
            </div>
            <div class="notice-text">
                <p>
                    Meta is changing the way your offline data will be used for attribution by migrating our pixels to a dataset.
                    This dataset will be used for both web and offline data.
                    Because of this change, you will need to add the new dataset for Offline tracking to your Ads.
                    At this point in time, this is a manual change that needs to take place at the ad level in your Ads Manager.
                    It will not be set up automatically upon deployment of this playbook.
                </p>
                <p>
                    You can follow this <a
                        href="https://www.loom.com/share/0b070f4d1b574351b6a50fada9d4669b"
                        target="_blank">video tutorial here</a>
                </p>
                <p>
                    Please reach out to <a
                        href="#"
                        @click.prevent="$store.dispatch('createOnboardingSupportTicket', 'Ad Deployment')">Support</a> if you need additional assistance.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import NotificationEmails from '@/components/globals/NotificationEmails.vue';
import { mapMutations } from 'vuex';

export default {
    name: 'SendNotification',
    components: {
        Icon,
        NotificationEmails
    },
    data() {
        return {
            showEditEmailInput: false
        };
    },
    computed: {
        emails: {
            get() {
                return this.$store.state.adDeployment.notification_emails;
            },
            set(emails) {
                this.setAdDeploymentValue({
                    key: 'notification_emails',
                    value: emails
                });
            }
        },
        sendNotifications: {
            get() {
                return this.$store.state.adDeployment.send_notifications;
            },
            set(sendNotifications) {
                this.setAdDeploymentValue({
                    key: 'send_notifications',
                    value: sendNotifications
                });
            }
        }
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        })
    }
};
</script>

<style lang="scss" scoped>
.send-notification-container {
    background-color: $white;
    padding: 10px 20px 20px 20px;
    border: 1px solid #D9D9D9;
    font-size: 14px;
}
.notice-title {
    margin-top: 30px;
    color: $blue-bg;
    margin-bottom: 15px;
}
.notice {
    padding: 15px;
    background-color: #FFECE7;
    display: flex;
}
.notice-icon {
    width: 30px;
}
.notice-text {
    color: #757575;
    width: calc(100% - 30px);
    font-size: 12px;
    a {
        color: $blue-bg;
    }
}
</style>
