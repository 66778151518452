<template>
    <div>
        <hub-spot-form
            v-for="platform in filteredPlatforms"
            ref="hsForms"
            :key="platform"
            hidden
            :field-values="fieldValues(platform)"
            :form-id="formId(platform)"
            :portal-id="portalId" />
    </div>
</template>

<script>
import HubSpotForm from '@/components/globals/HubSpotForm';
import { mapState } from 'vuex';
import {
    BB_STATUS_ACTIVE,
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK
} from '@/helpers/globals';
import { DMA, GEO } from '@/components/ad-deployment/store/constants';

export default {
    name: 'DynamicDeploymentHubSpotForm',
    components: {
        HubSpotForm
    },
    data() {
        return {
            portalId: '3753365',
            hubSpotPlatformName: {
                [PLATFORM_TIKTOK]: 'TikTok',
                [PLATFORM_SNAPCHAT]: 'Snapchat',
                [PLATFORM_PINTEREST]: 'Pinterest'
            },
            formIds: {
                [PLATFORM_TIKTOK]: '9cb1f8a8-48e3-4662-87ae-1fd85f51922c',
                [PLATFORM_SNAPCHAT]: '928bbf29-39ba-4552-8fef-b8f1a13f81b6',
                [PLATFORM_PINTEREST]: '556c50ec-33ff-4f91-af03-9ce384d02c26'
            }
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            platforms: state => state.adDeployment.platforms,
            adDeployment: state => state.adDeployment,
            currentAgency: state => state.agency.currentAgency,
            currentDealer: state => state.dealer.currentDealer,
        }),
        filteredPlatforms() {
            return this.platforms.filter(platform => platform !== PLATFORM_FACEBOOK);
        },
    },
    methods: {
        formId(platform) {
            return this.formIds[platform];
        },
        fieldValues(platform) {
            const dealerName = this.adDeployment.dealer_name ?? this.currentDealer.name;
            const campaignName = this.adDeployment.campaign_selection.all_platforms.campaign_name;

            return {
                'email': this.user.email,

                'TICKET.agency_name': this.currentAgency.name,
                'TICKET.agency_id': this.currentAgency.id,
                'TICKET.initial_ticket_owner_contact_name': this.user.name,
                'TICKET.ticket_contact_email': this.user.email,
                'TICKET.subject': this.ticketSubject(platform, dealerName, campaignName),
                'TICKET.content': this.ticketContent(platform, dealerName, campaignName),
                'TICKET.channel': [this.hubSpotPlatformName[platform]],
                'TICKET.dealer_id': this.adDeployment.dealer_id,
                'TICKET.form___dealer_name': dealerName,
                'TICKET.ticket___buyerbridge_id': this.adDeployment.dealer_id,
            };
        },
        ticketLocations(platform) {
            const locations = this.adDeployment.geo_targeting.platform_specific[platform].locations;

            return locations.map(location => {
                if (location.type === GEO) {
                    const addressContext = Object.keys(location?.address ?? {})?.length
                        ? `address: ${location.address?.street_number} ${location.address?.street}, ${location.address?.city} ${location.address?.region} ${location.address?.postal_code}, `
                        : `pinterestCustomToggle: ${this.adDeployment.custom_mile_pinterest}`;

                    return `[type: ${location.type}, ` +
                        `coordinates: [${location.coordinates.lat},${location.coordinates.lng}], ` +
                        `radius: ${location.radius}mi, ` +
                        `${addressContext}]`;
                } else if (location.type === DMA) {
                    return `[type: ${location.type}, name: ${location.name}, id: ${location.id}]`;
                }
            }).join(', ');
        },
        ticketSubject(platform, dealerName, campaignName) {
            return `Inventory - ${campaignName} - ${this.currentAgency.name} - ${dealerName} - ${this.hubSpotPlatformName[platform]}`;
        },
        ticketContent(platform, dealerName, campaignName) {
            const specialAdsCategory = this.currentAgency.name;
            const productGroupId = this.adDeployment.product_group_selection.platform_specific[platform].id;
            const productGroupName = this.adDeployment.product_group_selection.platform_specific[platform]?.name;
            const dynamicAudience = this.adDeployment.dynamic_audience_configuration.platform_specific[platform]?.radio_group_option;
            const destination = this.adDeployment.ad_destination.platform_specific[platform].radio_group_option;
            const copy = this.adDeployment.ad_copy.platform_specific[platform];
            const budgetType = this.adDeployment.budget.schedule;
            const startDate = this.adDeployment.budget.start_date_time ? this.adDeployment.budget.start_date_time : 'Immediately';
            const endDate = this.adDeployment.budget.start_date_time ? this.adDeployment.budget.start_date_time : 'Indefinitely';
            const budgetLevel = this.adDeployment.budget.platform_specific[platform].level;
            const spend = this.adDeployment.budget.platform_specific[platform][budgetLevel][budgetType === 'daily' ? 'daily_budget' : 'lifetime_budget'];
            const estimatedMonthlySpend = spend * 30;
            const enableCampaigns = this.adDeployment?.statuses?.platform_specific?.[platform]?.campaign?.status === BB_STATUS_ACTIVE;
            const enableAdSets = this.adDeployment?.statuses?.platform_specific?.[platform]?.adSet?.status === BB_STATUS_ACTIVE;
            const enableAds = this.adDeployment?.statuses?.platform_specific?.[platform]?.ad?.status === BB_STATUS_ACTIVE;
            const sendNotifications = this.adDeployment.send_notifications;

            return '• ' + 'Org Name: ' + this.currentAgency.name + '\n' +
                '• ' + 'Org Id: ' + this.currentAgency.id + '\n' +
                '• ' + 'Contact Email: ' + this.user.email + '\n' +
                '• ' + 'Contact Name: ' + this.user.name + '\n' +
                '• ' + 'Account Name: ' + dealerName + '\n' +
                '• ' + 'Account ID: ' + this.adDeployment.dealer_id + '\n' +
                '• ' + 'Channel: ' + this.hubSpotPlatformName[platform] + '\n' +
                '• ' + 'Campaign Name: ' + campaignName + '\n' +
                '• ' + 'Special Ads Category: ' + specialAdsCategory + '\n' +
                '• ' + 'Product Group Id: ' + productGroupId + '\n' +
                '• ' + 'Product Group Name: ' + productGroupName + '\n' +
                '• ' + 'Dynamic Audience: ' + dynamicAudience + '\n' +
                '• ' + 'Destination: ' + destination + '\n' +
                '• ' + 'Copy: ' + copy + '\n' +
                '• ' + 'Locations: ' + this.ticketLocations(platform) + '\n' +
                '• ' + 'Budget Type: ' + budgetType + '\n' +
                '• ' + 'Start: ' + startDate + '\n' +
                '• ' + 'End: ' + endDate + '\n' +
                '• ' + 'Budget Level: ' + budgetLevel + '\n' +
                '• ' + 'Spend: ' + spend + '\n' +
                '• ' + 'Est. Monthly: ' + estimatedMonthlySpend + '\n' +
                '• ' + 'Enable Campaigns: ' + enableCampaigns + '\n' +
                '• ' + 'Enable Ad Sets: ' + enableAdSets + '\n' +
                '• ' + 'Enable Ads: ' + enableAds + '\n' +
                '• ' + 'Send Notifications: ' + sendNotifications;
        }
    }
};
</script>
