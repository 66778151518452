<template>
    <!-- Last step will always be active once mounted -->
    <step-wrapper
        :index="index"
        :active="true"
        :step="name"
        title="Are you ready to deploy?"
        :help-link="helpLink"
        description="
            Please confirm that everything above looks
            correct and click deploy below once you're ready.
            The process will take several minutes while
            BuyerBridge deploys ads into your accounts.
        ">
        <v-alert
            :value="!allStepsValid"
            type="error"
            class="mb-4">
            There are errors with this deployment. Please
            review the steps above to ensure all are valid
            before proceeding.
        </v-alert>
        <v-alert
            :value="deployError"
            type="error"
            class="mb-4">
            {{ deployError }}
        </v-alert>
        <styled-dialog
            v-model="showAdvantagePlusModal"
            :width="600">
            <h3 class="mb-3">
                Advantage+ Creative Standard Enhancements is not supported for the
                {{ usingExistingCampaign ? 'campaign' : 'objective' }} that you have selected
            </h3>

            <p>
                Advantage+ Creative Standard Enhancements is not supported for the Campaign objective that you have
                selected in your Ad Launch.
                If you select continue below, we will turn off Advantage+ Creative Standard Enhancements for the Ad(s)
                that you are deploying.
                <template v-if="!usingExistingCampaign">
                    If you wish to keep Advantage+ Creative Standard Enhancements on, select cancel and select a
                    Campaign that supports Advantage+ Creative Standard Enhancements.
                </template>
                <template v-else>
                    If you wish to keep Advantage+ Creative Standard Enhancements on, select cancel and select the
                    Traffic objective.
                </template>
            </p>

            <div>
                <a
                    href=""
                    @click.stop.prevent="openAdvantagePlusSlideout">Learn More</a> about Advantage+ Creative Standard
                Enhancements.
            </div>
            <template #actions>
                <styled-button
                    outline
                    class="ml-auto"
                    @click="showAdvantagePlusModal = false">
                    Cancel
                </styled-button>
                <styled-button
                    class="ml-3"
                    @click="turnOffAdvantagePlusCreative">
                    Continue
                </styled-button>
            </template>
        </styled-dialog>

        <toggle-status v-if="shouldShowStatusToggles" />

        <send-notification />

        <div class="button-sad-holder mt-4">
            <styled-button
                small
                :disabled="!allStepsValid"
                :loading="loading"
                @click="handleDeploy">
                <span class="deploy-text">YES, DEPLOY</span>
            </styled-button>
        </div>

        <advantage-plus-slideout
            v-model="showAdvantagePlusSildeOut" />

        <dynamic-deployment-hub-spot-form />
    </step-wrapper>
</template>

<script>
import StepWrapper from '../../components/StepWrapper';
import StyledButton from '@/components/globals/StyledButton';
import StyledDialog from '@/components/globals/StyledDialog';
import stepProps from '../stepProps';
import ToggleStatus from '@/components/ad-deployment/steps/Review/ToggleStatus';
import AdvantagePlusSlideout from '@/components/ad-studio/editing/common/AdvantagePlusSlideout';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ADVANTAGE_PLUS_OBJECTIVES, BB_ADVANTAGE_PLUS_OBJECTIVES } from '../../store/constants';
import { PLATFORM_FACEBOOK } from '@/helpers/globals';
import SendNotification from '@/components/ad-deployment/steps/Review/SendNotification/index.vue';
import DynamicDeploymentHubSpotForm from '@/components/ad-deployment/components/DynamicDeploymentHubSpotForm.vue';
import EventBus from '@/event-bus';

export default {
    name: 'Review',
    components: {
        DynamicDeploymentHubSpotForm,
        StepWrapper,
        StyledButton,
        ToggleStatus,
        StyledDialog,
        AdvantagePlusSlideout,
        SendNotification
    },
    props: stepProps,
    data() {
        return {
            loading: false,
            deployError: null,
            showAdvantagePlusModal: false,
            showAdvantagePlusSildeOut: false,
            helpLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#preset-deploy'
        };
    },
    computed: {
        ...mapGetters({
            allStepsValid: 'adDeployment/allStepsValid'
        }),
        ...mapState({
            adDeployment: (state) => state.adDeployment,
        }),
        usingExistingCampaign() {
            return this.adDeployment.campaign_selection.selection === 'platform_specific';
        },
        shouldShowStatusToggles() {
            return this.$store.state.adDeployment.campaign_selection.selection === 'all_platforms';
        },
        shouldShowAdvantagePlusModal() {

            const deployingToFacebook = this.adDeployment?.platforms?.includes(PLATFORM_FACEBOOK);
            // If the user is not deploying to Facebook, we don't need to show the modal
            if (!deployingToFacebook) {
                return false;
            }

            const creatingNewCampaign = this.adDeployment?.campaign_selection?.selection === 'all_platforms';
            const deployingAnAdvantagePlusAd = this.adDeployment?.ads?.some(ad => ad?.configuration?.creative_enhancement);

            // The user is creating a new campaign
            // Make sure the objective supports advantage+ creative
            if (creatingNewCampaign && deployingAnAdvantagePlusAd) {
                const newFacebookCampaignObjective = this.adDeployment?.optimization?.platform_specific?.facebook?.objective;
                if (!ADVANTAGE_PLUS_OBJECTIVES.includes(newFacebookCampaignObjective)) {
                    // Show modal to turn off advantage+ creative
                    return true;
                }
            }

            // The user is using existing campaigns
            // Make sure the existing campaigns has an objective that supports advantage+ creative
            if (!creatingNewCampaign && deployingAnAdvantagePlusAd) {
                const facebookCampaignObjective = this.adDeployment?.campaign_selection?.platform_specific?.facebook?.campaign?.objective;
                if (!BB_ADVANTAGE_PLUS_OBJECTIVES.includes(facebookCampaignObjective.toLowerCase())) {
                    // Show modal to turn off advantage+ creative
                    return true;
                }
            }

            return false;
        },
    },
    methods: {
        ...mapActions({
            save: 'adDeployment/save',
            deploy: 'adDeployment/deploy',
            turnOffAdvantagePlus: 'adDeployment/turnOffAdvantagePlus'
        }),
        async handleDeploy() {
            if (this.shouldShowAdvantagePlusModal) {
                this.showAdvantagePlusModal = true;
                return;
            }

            try {
                this.loading = true;
                this.deployError = null;

                if (process.env.VUE_APP_APP_ENV === 'production') {
                    EventBus.$emit('submit-dynamic-deployment-forms');
                }

                await this.save();
                await this.deploy();
            } catch (error) {
                console.error('Error initiating deployments:', error.response ?? error);
                this.deployError = `
                    Error initiating deployments.  Please try again
                    or contact support if the problem continues.
                `;
            } finally {
                this.loading = false;
            }
        },
        turnOffAdvantagePlusCreative() {
            // This will turn off advantage plus creative
            // for all ads in the ad deployment
            this.turnOffAdvantagePlus();
            this.showAdvantagePlusModal = false;

            // Run the deploy function again
            this.handleDeploy();
        },
        openAdvantagePlusSlideout() {
            this.showAdvantagePlusModal = false;
            this.showAdvantagePlusSildeOut = true;
        }
    }
};
</script>
<style lang="scss" scoped>
.deploy-text {
    font-weight: 600;
}
</style>
