<template>
    <div>
        <div class="switcher-row bottom">
            <div>
                <v-switch
                    v-model="mainSwitchValue"
                    class="switcher ml-2"
                    :class="{ 'all-objects-yellow-switch': oneActive }"
                    color="primary"
                    @change="toggleMainSwitch" />
            </div>
            <div class="mb-1">
                Deploy with these ads enabled
                <icon-with-tooltip
                    class="single-icon"
                    has-tooltip
                    icon="question-circle"
                    size="18"
                    :tooltip="`Use this toggle to control whether each campaign, set and its underlying ads should be deployed as enabled (on) or disabled (off) across ${platformNames}. When enabled, your campaigns will begin spending across the selected platforms once ${scheduledStartDate ? `your scheduled start date (${startDateString}) arrives,` : ``} all ads are approved and your ads accounts have a valid payment method. After deployment, you can change the status under Campaign Management.`" />
                <span
                    class="advanced"
                    @click="toggleShowAdvanced">{{ showAdvanced ? 'simple' : 'advanced' }}</span>
            </div>
        </div>
        <div
            v-if="showAdvanced"
            class="ml-4">
            <div class="switcher-row bottom">
                <div>
                    <v-switch
                        v-model="campaignsSwitchValue"
                        class="switcher ml-2"
                        color="primary"
                        @change="toggleObjectSwitch('campaign')" />
                </div>
                <div class="mb-1">
                    Enable Campaigns
                    <icon-with-tooltip
                        class="single-icon"
                        has-tooltip
                        icon="question-circle"
                        size="18"
                        :tooltip="`Use this toggle to control whether each campaign should be deployed as enabled (on) or disabled (off) across ${platformNames}.`" />
                </div>
            </div>
            <div class="switcher-row bottom">
                <div>
                    <v-switch
                        v-model="adSetsSwitchValue"
                        class="switcher ml-2"
                        color="primary"
                        @change="toggleObjectSwitch('adSet')" />
                </div>
                <div class="mb-1">
                    Enable Ad Sets
                    <icon-with-tooltip
                        class="single-icon"
                        has-tooltip
                        icon="question-circle"
                        size="18"
                        :tooltip="`Use this toggle to control whether each set should be deployed as enabled (on) or disabled (off) across ${platformNames}. After deployment, you can change the status under Campaign Management.`" />
                </div>
            </div>
            <div class="switcher-row bottom">
                <div>
                    <v-switch
                        v-model="adsSwitchValue"
                        class="switcher ml-2"
                        color="primary"
                        @change="toggleObjectSwitch('ad')" />
                </div>
                <div class="mb-1">
                    Enable Ads
                    <icon-with-tooltip
                        class="single-icon"
                        has-tooltip
                        icon="question-circle"
                        size="18"
                        :tooltip="`Use this toggle to control all (${adCount}) ads should be deployed as enabled (on) or disabled (off) across ${platformNames}. After deployment, you can change the status under Campaign Management.`" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconWithTooltip from '@/components/ad-studio/editing/preview-step/IconWithTooltip';
import { BB_STATUS_ACTIVE, BB_STATUS_PAUSED } from '@/helpers/globals.js';
import { DEPLOYMENT_PLATFORMS } from '@/components/ad-deployment/store/constants.js';

export default {
    name: 'ToggleStatus',
    components: {
        IconWithTooltip
    },
    data() {
        return {
            showAdvanced: false,
        };
    },
    computed: {
        platforms() {
            return this.$store.state.adDeployment.platforms;
        },
        platformNames() {
            return this.platforms.map(platform => {
                return DEPLOYMENT_PLATFORMS.find(p => p.name === platform).ui_name;
            }).join(', ');
        },
        adCount() {
            return this.$store.state.adDeployment.ads.length;
        },
        campaignsSwitchValue: {
            get() {
                return this.switchValue('campaign');
            },
            set() {}
        },
        adSetsSwitchValue: {
            get() {
                return this.switchValue('adSet');
            },
            set() {}
        },
        adsSwitchValue: {
            get() {
                return this.switchValue('ad');
            },
            set() {}
        },
        mainSwitchValue: {
            get() {
                return this.campaignsSwitchValue || this.adSetsSwitchValue || this.adsSwitchValue;
            },
            set() {}
        },
        oneActive() {
            return (this.campaignsSwitchValue || this.adSetsSwitchValue || this.adsSwitchValue)
                && !(this.campaignsSwitchValue && this.adSetsSwitchValue && this.adsSwitchValue);
        },
        scheduledStartDate() {
            return this.$store.state.adDeployment.budget.start_date_time;
        },
        startDateString() {
            return this.scheduledStartDate ? new Date(this.scheduledStartDate).toLocaleDateString() : '';
        }
    },
    methods: {
        toggleShowAdvanced() {
            this.showAdvanced = !this.showAdvanced;
        },
        toggleMainSwitch() {
            this.$store.commit('adDeployment/updateStatusForSinglePlatformAndObjects', {
                value: this.mainSwitchValue ? BB_STATUS_PAUSED : BB_STATUS_ACTIVE,
                objects: ['campaign', 'adSet', 'ad']
            });
        },
        toggleObjectSwitch(object) {
            const objects = {
                campaign: this.campaignsSwitchValue,
                adSet: this.adSetsSwitchValue,
                ad: this.adsSwitchValue
            };

            this.$store.commit('adDeployment/updateStatusForSinglePlatformAndObjects', {
                value: objects[object] ? BB_STATUS_PAUSED : BB_STATUS_ACTIVE,
                objects: [object]
            });
        },
        switchValue(object) {
            return DEPLOYMENT_PLATFORMS.every(platform => {
                return this.$store.state?.adDeployment?.statuses?.platform_specific?.[platform.name]?.[object]?.status === BB_STATUS_ACTIVE;
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.deploy-text{
    font-weight: 600;
}

.advanced{
    color: #03A2EA;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 12px;
}

.switcher-row{
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    justify-content: flex-start;
}

.bottom{
    margin-bottom: -20px;
}
</style>

<style lang="scss">
.all-objects-yellow-switch {
    .theme--light.v-input--switch__track.primary--text {
        color: #FAB141 !important;
    }
}
</style>
